import React from 'react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';

import { withRouter } from '../../WithRouter';
import TeamsComponent from '../../TeamsComponent';
import { Link } from '@fluentui/react';


class BuildsView extends TeamsComponent {

    constructor(props) {
        super(props);
        this.state = {builds: null, branches: null, selectedBranch: null};
        this.debugUsername = 'iod@patentmaker.eu';
        this.filterRef = React.createRef();
    }


    teamsReady(myEmail) {
        window.pmusername = myEmail.split('@')[0];
        this.loadBuilds();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    loadBuilds() {
        fetch('/api/builds',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            builds: resp
                        });
                    });
                });
            }
        });

        var branches = [];
        branches.push({ label: 'Stable (2.8)', repoBranch: '2.8.1', product: 'PatentMakerClient' });
        branches.push({ label: 'Beta (2.9)', repoBranch: 'main', product: 'PatentMakerClient' });
        branches.push({ label: 'Web server', repoBranch: 'main', product: 'PatentMakerWeb' });
        this.setState((prevState) => {
            return ({
                ...prevState,
                branches: branches,
                selectedBranch: branches[0]
            });
        });
    }

    selectBranch(branch) {
        this.setState((prevState) => {
            return ({
                ...prevState,
                selectedBranch: branch
            });
        });
    }

    approveBuild(build) {
        fetch('/api/builds/' + build.id + '/approvereject/approve',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
                , method: 'post' }).then((response) => {
            build.approveReject = 'Approve';
            this.setState((prevState) => {
                return ({
                    ...prevState
                });
            });
        }).then(respText => {
        });
    }

    rejectBuild(build) {
        fetch('/api/builds/' + build.id + '/approvereject/reject',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
                , method: 'post' }).then((response) => {
            build.approveReject = 'Reject';
            this.setState((prevState) => {
                return ({
                    ...prevState
                });
            });
        }).then(respText => {
        });
    }

    render() {
        var branchOptions = [];
        if (this.state.branches) {
            this.state.branches.forEach(branch => {
                var fontWeight = 'normal';
                if (this.state.selectedBranch === branch) {
                    fontWeight = 'bold';
                }

                branchOptions.push(
                    <td>
                    <span style={{
                        textDecoration: 'underline',
                        marginRight: '2em',
                        fontSize: '1.2em',
                        fontWeight: fontWeight,
                        cursor: 'pointer'
                    }} onClick={() => this.selectBranch(branch)}>{branch.label}</span>
                </td>
                );
            });
        }

        var buildRows = [];
        if (this.state.builds && this.state.selectedBranch) {
            var rowBackground = '#ddeeff';
            var firstPending = true;
            this.state.builds.forEach(build => {
                if (build.repoBranch === this.state.selectedBranch.repoBranch &&
                    build.product === this.state.selectedBranch.product) {

                    var comments = [];
                    if (build.comment) {
                        build.comment.split('\n').forEach(comment => {
                            var jiraMatch = comment.match(/^\s*(PM-\d+):\s*(.*)$/);
                            if (jiraMatch) {
                                var jiraRef = jiraMatch[1];
                                var restOfComment = jiraMatch[2];
                                var jiraLink = 'https://patentmaker-team.atlassian.net/browse/' + jiraRef;
                                comments.push(<li><a href={jiraLink}>{jiraRef}</a>: {restOfComment}</li>);
                            } else {
                                comments.push(<li>{comment}</li>);
                            }
                        });
                    }

                    var approveRejectTimestampString = '';
                    if (build.approveRejectTimestamp && build.approveRejectTimestamp.length >= 10) {
                        approveRejectTimestampString = build.approveRejectTimestamp.substring(8, 10) +
                            '.' +
                            build.approveRejectTimestamp.substring(5, 7) +
                            '.' +
                            build.approveRejectTimestamp.substring(0, 4);
                    }

                    var approveRejectCell = <td />
                    if (build.approveReject === 'Approve') {
                        approveRejectCell = (
                            <td style={{ verticalAlign: 'top', width: '12em', padding: '0.5em 0 0.5em 1em' }}>
                                <table style={{ width: '100%' }}>
                                    <tr><td colSpan='2' style={{ fontWeight: 'bold', color: '#080' }}>Approved {approveRejectTimestampString}</td></tr>
                                </table>
                            </td>
                        );
                    } else if (build.approveReject === 'Reject') {
                        approveRejectCell = (
                            <td style={{ verticalAlign: 'top', width: '12em', padding: '0.5em 0 0.5em 1em' }}>
                                <table style={{ width: '100%' }}>
                                    <tr><td colSpan='2' style={{ fontWeight: 'bold', color: '#800' }}>Rejected {approveRejectTimestampString}</td></tr>
                                </table>
                            </td>
                        );
                    } else if (build.approveReject === 'Pending' && firstPending) {
                        approveRejectCell = (
                            <td style={{ verticalAlign: 'top', padding: '0.5em 0 0.5em 1em' }}>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ width: '6em' }}><span style={{
                                        color: '#080',
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }} onClick={() => this.approveBuild(build)}>Approve</span></td>
                                    <td style={{ width: '6em' }}><span style={{
                                        color: '#800',
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }} onClick={() => this.rejectBuild(build)}>Reject</span></td>
                                </tr>
                            </table>
                        </td>
                        );
                        firstPending = false;
                    }

                    var dateString = '';
                    if (build.timestamp && build.timestamp.length >= 10) {
                        dateString = build.timestamp.substring(8, 10) +
                            '.' +
                            build.timestamp.substring(5, 7) +
                            '.' +
                            build.timestamp.substring(0, 4);
                    }

                    // "unshift" to add at start/top - we want newest first (but want to parse bottom-up to easily identify
                    // the first unapproved build)
                    buildRows.unshift(
                        <tr style={{ borderBottom: 'solid 1px #999' }}>
                            <td style={{ verticalAlign: 'top', width: '8em', padding: '0.5em 0' }}>{build.version}</td>
                            <td style={{ verticalAlign: 'top', width: '10em', padding: '0.5em 0 0.5em 1em' }}>{dateString}</td>
                            <td style={{ verticalAlign: 'top', padding: '0.5em 0 0.5em 1em' }}><ul style={{ margin: '0 0 0 -0.75em'}}>{comments}</ul></td>
                            {approveRejectCell}
                    </tr>);

                    if (rowBackground === '#ddeeff')
                        rowBackground = '#eeffff';
                    else
                        rowBackground = '#ddeeff';
                }
            });
        }

        return (
            <div className='main-content' style={{ height: '100%' }}>
                <table style={{ margin: '1em' }}>
                    <tbody>
                        <tr>
                            {branchOptions}
                        </tr>
                    </tbody>
                </table>
                <table style={{ margin: '1em' }}>
                    <thead>
                        <tr>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Version</td>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline', paddingLeft: '1em' }}>Date</td>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline', paddingLeft: '1em' }}>Comments</td>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline', paddingLeft: '1em' }}>Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        {buildRows}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(BuildsView);
